/* Main app style sheet */

body {
  padding-bottom: 35px;
  background-color: #0085b2 !important;
}

/*------------------------- HEADER styles --------------------------------*/
#header.jumbotron {
  background-color: #0085b2 !important;
  padding : 10px 0;
  border: 0 none;
  margin-bottom: 0
}
#logo-sas {
  /*margin-top: 25px;*/
  width:300px
}
#logo-dirige {
  width: 140px;
}
#app-subtitle{
    color: #FFFFFF;
    font-size: 20px;
    font-family: "Arial Narrow", Arial, sans-serif;
    font-weight: 100 !important;
}

/*--------------------------- MENU styles --------------------------------*/
.navbar {
  border-radius: 0px;
  margin-bottom: 0px;
  border-bottom-width: 1px;
}
.navbar-default .navbar-nav>li>a {
  color: #0085b2;
  font-size: 18px;
  font-weight: 200;
}
.navbar-nav>li>a {
  padding-top: 25px;
  padding-bottom: 25px;
}
.navbar-inverse{
    background-color: #0085b2 !important;
}
.navbar-inverse .navbar-nav>li>a {
    color:white
}
.navbar-inverse .navbar-nav>.active>a{
    font-weight: 500 !important;
    color:white !important;
}
#sidebar {
    margin-top:20px;
}
/* affix position */
#sidebar.affix {
   top: 0;
}

/*----------------------- CONTENT AREA styles ----------------------------*/
#content {
  background-color: #fff;
  padding-bottom: 30px;
}
#page-content{
    padding-top:20px;
}

/*----------------------- FOOTER styles ----------------------------*/
#footer {
    padding-top: 30px;
}


#login-form .control-label {
    font-size: 20px;
}

.form-actions {
  margin-top: 40px;
}

.btn-toolbar-actions {
    margin-bottom: 10px;
}

.btn-lateral-menu {
    text-align: left;
    text-transform: none;
    padding: 9px 12px 7px !important;
    margin-bottom: 8px;
    color: #8B8B8B;
    margin-top: 1px;
    border-bottom-width:1px
}

.btn-lateral-menu:hover {
    /*margin-bottom: 9px;*/
    border-bottom-width:1px
}

.btn-lateral-menu .label{
    margin-right: 5px;
}

/* bootstrap theme changes */

    .page-header {
        border-bottom: none;
        margin: 20px 0 0px;
    }

    /*warning color*/
    .alert-warning, .label-warning{
        background-color: #FF7348;
    }
    .text-warning{
        color: #FF7348;
    }
    /*success color*/
    .alert-success, .label-success{
        background-color: #40C594;
    }
    .text-success{
        color: #40C594;
    }
    /*http://twitterbootstrap3buttons.w3masters.nl*/
    .btn-success {
      background-color: #40C594;
      border-color: #31a178;
    }
    .btn-success:hover,
    .btn-success:focus,
    .btn-success:active,
    .btn-success.active {
      background-color: #37b586;
      border-color: #31a178;
    }
    .btn-success.disabled:hover,
    .btn-success.disabled:focus,
    .btn-success.disabled:active,
    .btn-success.disabled.active,
    .btn-success[disabled]:hover,
    .btn-success[disabled]:focus,
    .btn-success[disabled]:active,
    .btn-success[disabled].active,
    fieldset[disabled] .btn-success:hover,
    fieldset[disabled] .btn-success:focus,
    fieldset[disabled] .btn-success:active,
    fieldset[disabled] .btn-success.active {
      background-color: #40C594;
      border-color: #40C594;
    }

    .btn,.dropdown-menu{
        border-width:1px !important;
    }


/* END bootstrap theme changes */


/* tablesorter sort icons */

    .tablesorter-bootstrap .tablesorter-header {
        background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
        background-position: center right;
        background-repeat: no-repeat;
        cursor: pointer;
        white-space: normal;
        padding: 4px 20px 4px 4px;
    }
    .tablesorter-bootstrap thead .headerSortUp,
    .tablesorter-bootstrap thead .tablesorter-headerSortUp,
    .tablesorter-bootstrap thead .tablesorter-headerAsc {
        background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
        border-bottom: #000 1px solid;
    }
    .tablesorter-bootstrap thead .headerSortDown,
    .tablesorter-bootstrap thead .tablesorter-headerSortDown,
    .tablesorter-bootstrap thead .tablesorter-headerDesc {
        background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
        border-bottom: #000 1px solid;
    }
    .tablesorter-bootstrap thead .sorter-false {
        background-image: none;
    }

/* END tablesorter sort icons */


/* Solicitud page */

  body {
    padding-bottom: 35px;
  }

  h3 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .buttons {
    padding: 10px 0px;
    background-color: #fff;
  }
  .table>thead>tr>th,.table>tbody>tr>th, table>tfoot>tr>th, .table>tfoot>tr>th, table>thead>tr>td, .table>thead>tr>td, table>tbody>tr>td, .table>tbody>tr>td, table>tfoot>tr>td, .table>tfoot>tr>td {
    border-color: #ddd;
  }

  #personal-data {
    border-color: #75caeb;
    border-width: 1px 1px 1px 1px;
    border-radius: 3px;
  }

  #personal-data>.panel-heading h3 {
    color: #008BBC;
  }

  #personal-data .panel-body .input-group {
    margin-bottom: 20px;
  }

  .table-data input.form-control {
    width: auto;
  }
  .table-data > tbody > tr > td {
    vertical-align: middle;
  }
  .table-reglas > tbody > tr > td {
    vertical-align: middle;
   }
  .table-reglas label {
      margin-bottom: 0;
   }
  .panel-info {
    border-color: #75caeb;
  }
  .panel-info >.panel-footer {
    background-color: #75caeb;
  }
  .panel-warning {
      border-color: #ff851b;
   }
  .panel-warning >.panel-footer {
      background-color: #ff851b;
    }
  .panel-success {
    border-color: #28b62c
  }
  .panel-success > .panel-heading,
  .panel-success .panel-footer {
    background-color: #d5f6d6;
  }
  .rating-select {
    margin-bottom: 20px;
  }
  .rating-value > .input-group-addon {
    border: 1px solid #28b62c;
    color:#28b62c;
    font-weight: bold;
    border-right: none;
  }
  .rating-value > .form-control[readonly] {
    background-color: #d5f6d6;
    box-shadow: inset 0 2px 0 rgba(0,0,0,0.075);
    border: 1px solid #28b62c;
    border-left: none;
    font-weight: bold;
    font-size: 1.5em;
    text-align: right;
  }

  .radio-regla-baremacion input {
    width:1.4em;
    height:1.4em;
  }

  .radiotext label {
    cursor: pointer;
  }

  .group {
    margin-bottom: 80px;
  }

  .blue-background {
     background-color:#127ba3
  }

/* END solicitud page */

/* selectpicker fix */
/*https://github.com/silviomoreto/bootstrap-select/issues/514*/
/*.btn-group.bootstrap-select select {
    width: 1px !important;
}*/

/* Change jstree nodes height */
.jstree-leaf {
    font-size: 17px;
    height: 23px;
}
.jstree-leaf a.jstree-hovered {
    height: 23px;
}
.jstree-leaf a.jstree-clicked {
    height: 23px;
}

/* acciones */
 a.accion-id-503{
     color: #ffffff;
     background-color: #ff4136;
     border-color: #ff291c;
}
 a.accion-id-502, a.accion-id-1 {
     color: #fff;
     background-color: #5cb85c;
     border-color: #4cae4c;
}
 a.accion-id-1 {
     padding: 10px 16px;
     font-size: 18px;
     line-height: 1.33;
     border-radius: 6px;
}

.breadcrumb{
    display:none
}

table>thead>tr>td{
    font-size: 12px !important
}

.pager {
    padding-left:15px;
    margin: 0;
    text-align: left;

}

.solicitud-modificada td{
    background-color: #ffb9a3 !important
}
/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*https://scotch.io/tutorials/default-sizes-for-twitter-bootstraps-media-queries*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

    .btn-lateral-menu {
        font-size: 15px;
        margin-bottom: 10px;
    }

    .btn-lateral-menu:hover {
        margin-bottom: 11px;
    }

}


/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {

}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
    #sidebar.affix {
       position: relative !important;
       top: 0 !important;
    }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}
